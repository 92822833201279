
<template>
  <div id="component-list-category">
    <div>
      <div class="d-flex justify-content-center">
        <b-form-group
            v-for="(center, index) in CenterModule.centers" :key="index"
        >
          <b-form-radio
              class="ml-2"
              v-model="selectFilterCategory"
              name="filter-radios"
              :value="center.id"
              v-on:change="selectedCategoryCenter"
          >
            {{ center.name }}
          </b-form-radio>
        </b-form-group>
        <b-form-radio
            class="ml-2"
            v-model="selectFilterCategory"
            name="filter-radios"
            :value="null"
            v-on:change="selectedCategoryCenter"
        >
          Tous les centres
        </b-form-radio>
      </div>

    </div>

    <div class="card p-1 card-border">
      <div class="card-body">
        <select class="form-control" v-on:change="selectedParentCategory">
          <option disabled :selected="!isCategorySelected.parent" :value="null">Choisir une categorie</option>
          <option
              v-for="(parent, index) in CategoryModule.parentCategories" :key="index"
              class="p-2 c-pointer"
              :value="parent.id"
          >
            {{ parent.name }}
          </option>
        </select>
      </div>
    </div>

    <h5 class="mt-2" v-if="selectParentCategory">
      Choisir une categorie enfante de la categorie :
      <span class="text-info">{{ selectParentCategory.name }}</span>
    </h5>

    <div class="card p-1 card-border" v-if="selectParentCategory">
      <div class="card-body">
        <select class="form-control" v-on:click="emitSelectCategory">
          <option disabled :selected="!isCategorySelected.children" :value="null">Choisir une categorie enfante</option>
          <option
              v-for="(cat, index) in selectParentCategory.childs" :key="index"
              class="d-flex p-2 c-pointer"
              :value="cat.id"
          >
            {{ cat.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      selectParentCategory: null,
      selectFilterCategory: null,
      isParent: null,
      isCategorySelected: {
        parent: false,
        children: false
      }
    }
  },
  created() {
    this.$store.dispatch('fetchParentCategories', {
      perPage: null,
      page: null
    })
  },
  methods: {
    selectedParentCategory($event) {
      this.$swal({
        title: 'Voulez vous choisir cette catégorie ?',
        text: 'Voulez choisir cette catégorie ou une catégorie enfante ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2FA76E',
        cancelButtonColor: '#17a2b8',
        confirmButtonText: 'Choisir cette catégorie parente',
        cancelButtonText: 'Choisir une catégorie enfante'
      }).then((result) => {
        if (result.value) {
          this.isCategorySelected.parent = true;
          this.selectParentCategory = null
          this.isParent = true
          this.emitSelectCategory($event)
        } else {
          this.selectParentCategory = this.$store.getters.getParentCategoryById(Number($event.target.value))
          this.isParent = false
          this.isCategorySelected.parent = true;
        }
      })
    },
    emitSelectCategory($event) {
      this.isCategorySelected.children = true;
      this.$emit('emit-select-category', {
        category: $event.target.value,
        isParent: this.isParent
      })
    },
    selectedCategoryCenter($event) {
      this.$emit('emit-select-category-center', {
        center: $event,
      })
    }
  },
  computed: {
    ...mapState(['CategoryModule']),
    ...mapState(['CenterModule']),
  },
}
</script>

<style scoped>

</style>
